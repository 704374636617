.statisticsArea {
  display: flex;
  justify-content: center;
}
.statCardArea {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.statCard {
  height: 150px;
  width: 250px;
  box-shadow: 5px 5px 5px 0 rgb(216, 216, 216);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
}
.statTitle {
  font-size: 15px;
  color: #4d4c4c;
  font-weight: 600;
}
.amount {
  font-size: 40px;
  font-weight: 600;
  color: #e2414c;
}

@media only screen and (max-width: 768px) {
  .statCardArea{
    grid-template-columns: 1fr 1fr;
  }
  .statCard{
    height: 100%;
    width: 100%;
    margin:15px;
  }
}