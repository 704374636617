.upload_btn {
	height: 40;
	width: 150;
	margin: 10;
	background: #4d4c4c;
	border: none;
	color: #fff;
	border-radius: 5;
	cursor: pointer;
}

.__gallary_photo_wrapper{
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
}

.__gallary_photo{
	width: 100%;
	max-height: 300px;
}

.__photo_card{
	border: 5px solid #ddd;
	position:relative;
	padding:20px
}

.__galary_action{
	position: absolute;
	bottom: 0;
	right: 10px;

}

.__galary_action button{
	margin-left: 10px;
	border:1px solid #e2414c;
	cursor: pointer;
	padding:2px 5px;
	border-radius: 5px;

}

.__galary_action button:hover{
	background: #e2414c;
	color:#fff;
	
}


@media screen and (max-width: 600px) {
	.__gallary_photo_wrapper{
		width: 100%;
		display: grid;
		grid-template-columns: 1fr;
	}
  }
  