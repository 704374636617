.navbar {
  background-color: #fff;
  box-shadow: 0px 5px 5px 0px rgb(220, 220, 220);
  padding: 0 20px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navLinks {
  display: flex;
}

.navLinks li {
  margin: 0 20px;
  list-style: none;
}
.navLinks li a {
  text-decoration: none;
  font-weight: 600;
  color: #e2414c;
}
.navBtn {
  background-color: #e2414c;
  border: none;
  height: 30px;
  width: 100px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}

.logout_item{
  display: none;
}

@media only screen and (max-width: 768px) {
  .navbar {
    display: flex;
    flex-direction: column;
    height:70px ;
    padding: 0px 5px;
  }
  
  
  .navLinks {
    display: flex;
    /* flex-direction: column; */
    justify-content: space-around;
    width: 100%;
  }

  .navLinks li a {
    font-size: 15px;
  }

  .navLinks li {
    padding: 0px;
    margin: 0 5px;
    list-style: none;
  }

  ul {
    list-style-type: disc;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
  }


  .logout{
    display: none;
  }
  .logout_item{
    display: block;
  }
  
}